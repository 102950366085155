import React from 'react';
import './ProductViewComponent.css';
import {
    fav_icon,
    store_name_store_icon
} from '../data/LocalImageLoader';

const ProductViewComponent = ({ product, onBack }) => {
    return (
        <div className="product-view">
            <div className="back-button" onClick={onBack} >
                <a>Back</a>
            </div>
            <div className="product-details">
                <div className="product-details-header">
                    <div className="product-details-header-imagebox">
                        <img src={store_name_store_icon} className="product-details-header-vendor-image" />
                    </div>
                    <div className="product-details-header-textbox">
                        <div className="product-details-header-text-name">
                            {product.name}
                        </div>
                        <div className="product-details-header-text-distance">
                            3km / 15 min walk
                        </div>
                    </div>
                </div>
                <img className='productImage' src={product.image} alt={product.name} />
                <div className='favoriteIcon'>
                    <img className='productImage' src={fav_icon} alt={product.name} />
                </div>
                <div className='productDescriptionText'>Description {/* product.description */}</div>
                <div className='productCategoryText'>Category {product.category}</div> 
            </div>
        </div>
    );
};

export default ProductViewComponent;
