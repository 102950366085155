import React, { useState, useEffect } from 'react';
import { Sheet } from 'react-modal-sheet';
import ProductComponent from './ProductComponent';
import './VendorProductsComponent.css';

import ProductViewComponent from './ProductViewComponent';

import { snapPointsDefault, initialSnapDefault } from '../data/default_values';

const VendorProductsComponent = ({ isOpen, onClose, products = [], snapToPosition }) => {
  const [snapPoints] = useState(snapPointsDefault);
  const [currentSnap, setCurrentSnap] = useState(initialSnapDefault); // Default to minimized position (0.05)
  const [snapPoint, setSnapPoint] = useState(snapPointsDefault[initialSnapDefault]); // Use the minimized snap point
  const [localProducts, setLocalProducts] = useState(products);
  
  const [selectedProduct, setSelectedProduct] = useState(null);
  
  const name = "Store Name";
  const distance = "3km / 15min";

  useEffect(() => {
    if (isOpen) {
      setCurrentSnap(snapToPosition);
      setSnapPoint(snapPoints[snapToPosition]);
      setLocalProducts(products);
    } else {
      setCurrentSnap(initialSnapDefault); // Reset to minimized position
      setSnapPoint(snapPoints[initialSnapDefault]); // Use the minimized snap point
    }
  }, [isOpen, snapToPosition, snapPoints, products]);

  const handleSnap = (snapIndex) => {
    setSnapPoint(snapPoints[snapIndex]);
    setCurrentSnap(snapIndex);
    if (snapPoints[snapIndex] === 0.05) {
      setLocalProducts([]);
    } else {
      setLocalProducts(products);
    }
  };

  const handleSheetClose = () => {
    setCurrentSnap(initialSnapDefault); // Reset to minimized position
    setSnapPoint(snapPoints[initialSnapDefault]); // Use the minimized snap point
    setLocalProducts([]);
    setSelectedProduct(null); // Reset selected product on close
  };

  const handleProductClick = (product) => {
    setSelectedProduct(product);
  };

  const handleBackButtonClick = () => {
    setSelectedProduct(null);
  };

  return (
    <Sheet
      isOpen={isOpen}
      onClose={handleSheetClose} // Override onClose to snap to initial position
      snapPoints={snapPoints}
      initialSnap={currentSnap}
      onSnap={handleSnap}
    >
      <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content>
          <div
            className="product-grid"
            style={{
              height: `calc(90vh * ${snapPoint})`,
              overflowY: snapPoint === 0.5 || snapPoint === 0.9 ? 'scroll' : 'auto'
            }}
          >
            {selectedProduct ? (
              <ProductViewComponent product={selectedProduct} onBack={handleBackButtonClick} />
            ) : (
              localProducts.length > 0 ? (
                localProducts.map((product, index) => (
                  <ProductComponent 
                    key={index} 
                    product={product} 
                    name={name} 
                    distance={distance}
                    onClick={() => handleProductClick(product)} /* OnClick action */
                  />
                ))
              ) : (
                <p>No products available</p>
              )
            )}
          </div>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop style={{ backgroundColor: 'transparent' }} />
    </Sheet>
  );
};

export default VendorProductsComponent;
