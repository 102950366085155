// Importing local images
import circleBlueSmall from '../images/placeholders/dot-default-blue-small.png';
import circleRedSmall from '../images/placeholders/dot-default-red-small.png';
import userlocation from '../images/placeholders/user_location.png';

import product1 from '../images/products/product-1.jpg';
import product2 from '../images/products/product-2.jpg';
import product3 from '../images/products/product-3.jpg';
import product4 from '../images/products/product-4.png';
import product5 from '../images/products/product-5.png';
import product6 from '../images/products/product-6.png';

import store_icon from  '../images/placeholders/store_default.png';
import user_icon from  '../images/placeholders/user_default.png';

import favorite_icon from '../images/icons/fav_icon.png';

export const circle_small_blue = circleBlueSmall;
export const circle_small_red = circleRedSmall;
export const user_location_icon = userlocation;

export const p1_image = product1;
export const p2_image = product2;
export const p3_image = product3;
export const p4_image = product4;
export const p5_image = product5;
export const p6_image = product6;

export const store_name_store_icon = store_icon;
export const store_name_user_icon = user_icon;

export const fav_icon = favorite_icon;