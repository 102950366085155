import React, { useState, useEffect } from 'react';
import GoogleMapComponent from '../components/GoogleMapComponent';
import VendorProductsComponent from '../components/VendorProductsComponent';
import { fetchVendorData } from '../data/DataLoader';
import './MapPage.css';

import logo from '../images/logo/PP_Blue.svg';//'../logo.svg';

function MapPage() {
  const [isSheetOpen, setSheetOpen] = useState(true); // Ensure the sheet is always open initially
  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [snapToPosition, setSnapToPosition] = useState(2); // Initial snap to bottom (minimized)
  const [mapCenter, setMapCenter] = useState({ lat: 45.5017, lng: -73.5673 }); // Default center (Montreal)
  const [mapZoom, setMapZoom] = useState(12); // Default zoom level

  useEffect(() => {
    // Request the user's location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setMapCenter({ lat: latitude, lng: longitude });
          setMapZoom(14); // Adjust the zoom level if needed
        },
        (error) => {
          console.error("Error getting user's location:", error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }

    loadVendorData();
  }, []);

  const loadVendorData = () => {
    fetchVendorData('/data/pop_vendors - STORES.csv', setVendors);
  };

  const handleLogoClick = () => {
    console.log('Logo clicked');
    setSheetOpen(true);
  };

  const handleMarkerClick = (vendor) => {
    setSelectedVendor(vendor);
    setSnapToPosition(1); // Snap to middle on marker click
    setSheetOpen(true);
  };

  const handleSheetClose = () => {
    setSheetOpen(false);
    setSelectedVendor(null);
    setSnapToPosition(2); // Reset to initial position when closed
  };

  return (
    <div className="map-page">
      <div className="logo-container">
        <img src={logo} alt="Logo" className="logo" onClick={handleLogoClick} />
      </div>
      <div className="google-map-container">
        <GoogleMapComponent
          vendors={vendors}
          onMarkerClick={handleMarkerClick}
          center={mapCenter}
          zoom={mapZoom}
        />
      </div>
      <VendorProductsComponent
        isOpen={isSheetOpen}
        onClose={handleSheetClose}
        products={selectedVendor ? selectedVendor.products : []} // Show products if a vendor is selected
        snapToPosition={snapToPosition} // Pass the snap position
      />
    </div>
  );
}

export default MapPage;
