import React, { useEffect, useRef, useState } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import {
  mtl_lat,
  mtl_lng,
  mtl_default_zoom,
  GoogleMapsApiKey,
  GoogleMapsOptions
} from '../data/default_values';

import { 
  circle_small_blue,
  circle_small_red,
  user_location_icon
} from '../data/LocalImageLoader';

const containerStyle = {
  width: '100%',
  height: '100%'
};

const center = {
  lat: mtl_lat, // Default latitude for Montreal
  lng: mtl_lng, // Default longitude for Montreal
};

const GoogleMapComponent = ({ vendors, onMarkerClick }) => {
  const mapRef = useRef(null);
  const googleMapRef = useRef(null);
  const [markers, setMarkers] = useState([]);
  const userMarkerRef = useRef(null); // Reference to the user's marker

  useEffect(() => {
    const loader = new Loader({
      apiKey: GoogleMapsApiKey,
      version: 'weekly',
    });

    loader.load().then(() => {
      const handleLocationError = (browserHasGeolocation, pos) => {
        const infoWindow = new window.google.maps.InfoWindow();
        infoWindow.setPosition(pos);
        infoWindow.setContent(
          browserHasGeolocation
            ? 'Error: The Geolocation service failed.'
            : 'Error: Your browser doesn\'t support geolocation.'
        );
        infoWindow.open(mapRef.current);
      };

      if (!mapRef.current) {
        const map = new window.google.maps.Map(googleMapRef.current, {
          center,
          zoom: mtl_default_zoom,
          ...GoogleMapsOptions,
          gestureHandling: 'greedy', // Change to 'greedy' for single-finger panning, (options: 'none', 'auto', 'cooperative' and 'greedy')
        });

        mapRef.current = map;

        if (navigator.geolocation) {
          navigator.geolocation.watchPosition(
            (position) => {
              const pos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              };

              if (userMarkerRef.current) {
                // Update existing marker position
                userMarkerRef.current.setPosition(pos);
              } else {
                // Create a new marker for the user's location
                userMarkerRef.current = new window.google.maps.Marker({
                  position: pos,
                  map,
                  title: 'Your location',
                  icon: {
                    url: user_location_icon, // Custom user location icon
                    scaledSize: new window.google.maps.Size(20, 20), // Adjust the size as needed
                  },
                });
              }

              // Optionally, center the map on the user's position
              // map.setCenter(pos);
            },
            () => {
              handleLocationError(true, map.getCenter());
            },
            { enableHighAccuracy: true }
          );
        } else {
          // Browser doesn't support Geolocation
          handleLocationError(false, map.getCenter());
        }
      }

      const google = window.google;
      const map = mapRef.current;

      // Clear existing markers
      markers.forEach(marker => marker.setMap(null));
      const newMarkers = [];

      vendors.forEach((vendor, index) => {
        if (vendor.COORDINATES) {
          const [lat, lng] = vendor.COORDINATES.split(',').map(coord => parseFloat(coord.trim()));

          if (!isNaN(lat) && !isNaN(lng)) {
            // just  to set the first  6 to red
            const markerIcon = index < 6 ? circle_small_red : circle_small_blue;

            const marker = new google.maps.Marker({
              position: { lat, lng },
              map,
              title: vendor.STORE_NAME,
              icon: {
                url: markerIcon, // Specify the path to your custom icon
                scaledSize: new google.maps.Size(25, 25), // Adjust the size as needed
              },
            });

            marker.addListener('click', () => {
              onMarkerClick(vendor);
            });

            newMarkers.push(marker);
          } else {
            console.error('Invalid latitude or longitude for vendor:', vendor);
          }
        } else {
          console.error('Missing coordinates for vendor:', vendor);
        }
      });

      // Update the state with new markers
      setMarkers(newMarkers);

    }).catch(error => {
      console.error('Error loading Google Maps:', error);
    });
  }, [vendors, onMarkerClick]);

  return <div ref={googleMapRef} style={containerStyle}></div>;
};

export default GoogleMapComponent;
