import Papa from 'papaparse';
import { 
  p1_image,
  p2_image,
  p3_image,
  p4_image,
  p5_image,
  p6_image
} from '../data/LocalImageLoader';

export const fetchVendorData = (csvFilePath, onVendorsLoaded) => {
  fetch(csvFilePath)
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.text();
    })
    .then(data => {
      parseVendorData(data, onVendorsLoaded);
    })
    .catch(error => {
      console.error('Error fetching CSV file:', error);
    });
};

const parseVendorData = (csvData, onVendorsLoaded) => {
  Papa.parse(csvData, {
    header: true,
    dynamicTyping: true,
    complete: (results) => {
      console.log('Parsed CSV results:', results.data);
      const vendorsWithCoords = results.data.map(vendor => {
        if (vendor.COORDINATES) {
          const coords = vendor.COORDINATES.split(',');
          if (coords.length === 2) {
            const lat = parseFloat(coords[0].trim());
            const lng = parseFloat(coords[1].trim());
            return {
              ...vendor,
              latitude: lat,
              longitude: lng,
              products: [
                { name: 'Product 1', image: p1_image },//'URL'
                { name: 'Product 2', image: p2_image },
                { name: 'Product 3', image: p3_image },
                { name: 'Product 4', image: p4_image },
                { name: 'Product 5', image: p5_image},
                { name: 'Product 6', image: p6_image }
              ]
            };
          } else {
            console.error('Invalid coordinates format for vendor:', vendor);
          }
        } else {
          console.error('Missing coordinates for vendor:', vendor);
        }
        return vendor;
      });
      onVendorsLoaded(vendorsWithCoords);
    },
    error: (error) => {
      console.error('Error parsing CSV file:', error);
    }
  });
};
