//Montreal - 45.5019, -73.5674  (LONDON - [51.505, -0.09], 13)

//Montreal
export const mtl_lat = 45.5019;
export const mtl_lng = -73.5674;
export const mtl_default_zoom = 12;

//Placeholders
export const logo_default_URL = 'https://png.pngtree.com/png-vector/20220913/ourmid/pngtree-i-e-comerce-logo-png-image_6144193.png';
//export const vendor_default_URL = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTw_HeSzHfBorKS4muw4IIeVvvRgnhyO8Gn8w&s";
export const vendor_default_URL = ""

export const marker_default_URL = 'https://pngimg.com/uploads/dot/dot_PNG4.png'

//snapping points for model-sheet (pull up)
export const snapPointsDefault = [
    0.9,
    0.5,
    0.05
]; 
export const initialSnapDefault = 2;  //0.05

//GOOGLE MAP API KEY
export const GoogleMapsApiKey = 'AIzaSyDOxJwQpsmQXCQomoiKsX-El2OAgQGmMUg';
export const mtl_default_styleID = '714af70d3c474ef0';
export const GoogleMapsOptions = {
  disableDefaultUI: true, // Disables all default UI elements
  mapId: mtl_default_styleID, // Replace with your Map Style ID
};
