import React from 'react';
import './ProductComponent.css';
import {store_name_store_icon} from '../data/LocalImageLoader';

const ProductComponent = ({ product, name, distance, onClick }) => {
  return (
    <div className="product">
      <div className="vendor-header-container">
        <div className="vendor-image-container">
          <img src={store_name_store_icon} alt={name} className="vendor-image" />
        </div>
        <div className="vendor-detail">
          <p className="vendor-name">{name}</p>
          <p className="vendor-distance">{distance}</p>
        </div>
      </div>
      
      <img src={product.image} alt={product.name} className="product-image" onClick={onClick}/>
    </div>
  );
};

export default ProductComponent;
